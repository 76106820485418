import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

import LogoGithub from 'react-ionicons/lib/LogoGithub';
import LogoTwitter from 'react-ionicons/lib/LogoTwitter';
import LogoLinkedin from 'react-ionicons/lib/LogoLinkedin';

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props;
    const siteTitle = data.site.siteMetadata.title;

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO site={data.site} title="About Krzysztof Witczak" />
      
        <h2 className="about-header">Who am I</h2>
        <div className="about-content">
          <div className="about-text">
            <p>My name is <b>Krzysztof Witczak</b> and I live in Poznań, Poland.
             I've been coding professionally since 2015, and I'm currently happily employed as Head of Engineering @ <a href="https://www.globalapptesting.com/">Global App Testing</a>.</p>
            <p>Technically I specialize in <b>Ruby on Rails</b> and <b>JavaScript</b>, but lately I learn mostly about leadership and management.
             I believe that one of my core talents is... growing other people, finding inspiring career development plans and encouraging my teammates to climb them. </p>
            <p>Besides that, I'm a huge tabletop RPG fan 🎲🧙‍♂️😃!</p>
            <p>What to know more? Check this out 👉 <a href="https://managerreadme.com/readme/kwitczak19">.README</a></p>
          </div>
        </div>

        <h2 className="about-header">Contact me</h2>
        <div className="contact-content">
          <p>Feel free to contact me on any of the following platforms.<br/>
           For business related offers I preffer <strong>LinkedIn</strong>.
          </p>
          <div className="contact-icons">
            <a title="My GitHub account" target="_blank" href="https://github.com/kwitczak"><LogoGithub fontSize="40px" color="#4d4950" /></a>
            <a title="My Twitter account" target="_blank" href="https://twitter.com/K_Witczak_"><LogoTwitter fontSize="40px" color="#4d4950" /></a>
            <a title="My LinkedIn account" target="_blank" href="https://www.linkedin.com/in/krzysztof-witczak-3532807a/"><LogoLinkedin fontSize="40px" color="#4d4950" /></a>
          </div>
        </div>
      </Layout>
    );
  }
}

BlogIndex.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }).isRequired,
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  }).isRequired
};

export default BlogIndex;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
        metaTitle
      }
    }
  }
`;
